<template>
  <div class="container">
    <Head-section></Head-section>
    <b-card title="Error Page!">
      <b-card-text>
        <h2>
          <i class="fa fa-exclamation-triangle"></i>
        </h2>
        <p>The page you visited does not exist</p>
      </b-card-text>
    </b-card>
    <Foot-section></Foot-section>
  </div>
</template>
<script>
import HeadSection from "./StoreHeadSection.vue";
export default {
  name: "PayErro",
  components: { HeadSection }
};
</script>

